import * as React from "react";
import styled from "styled-components"

import StaticPageTemplate from "./../components/staticPageTemplate"

const GoogleFormContainer=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
`

const FormPage = ({data}) => {
  return (
    <StaticPageTemplate title={"Formularz"}>
        <GoogleFormContainer>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScHffd-eE79K8Q7iLQPOoqEgmAs9WZs9UvPZYgT-w0vKb0bjQ/viewform?embedded=true" width="640" height="1300" frameborder="0" marginheight="0" marginwidth="0">Ładuję…</iframe>
        </GoogleFormContainer>
    </StaticPageTemplate>
  )
}

export default FormPage;