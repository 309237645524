import * as React from "react"
import styled from "styled-components"
import { keyframes } from "styled-components";
import {breakpoint} from "styled-components-breakpoint";

const StaticPageContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const HeaderAnimation = keyframes`
    from{
        width: 0;
    }
    to{
        witdh: 110%;
    }
`

const StaticPageHeader = styled.h1`
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: bold;
    padding-left: 30px;
    color: #363537;
    width: fit-content;

    &:after{
        content:"";
        display: block;
        width: 70%;
        ${breakpoint("tablet") `
            width: calc( 100% + 50px);
        `}
        height: 4px;
        background: #FF7171;

        position: relative;
        left: -30px;
        top: 5px;

        animation: ${HeaderAnimation} .8s ease-out;
    }
`

const StaticPageContent = styled.div`
    font-family: "Open Sans", sans-serif;

    margin: 0px 12%;
`

const StaticPageTemplate = (props) => {
  return (
    <StaticPageContainer>
        <StaticPageHeader>{props.title}</StaticPageHeader>
        <StaticPageContent>{props.children}</StaticPageContent>
    </StaticPageContainer>
  )
}

export default StaticPageTemplate;